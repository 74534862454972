import { createStore } from 'vuex';
import { fetchUserData } from '@/functions/utils';

// Function to safely access sessionStorage
function getSessionStorageItem(key, defaultValue) {
  if (typeof window !== 'undefined' && sessionStorage) {
    return sessionStorage.getItem(key) || defaultValue;
  }
  return defaultValue;
}

const defaultRpcUrl = getSessionStorageItem('rpcUrl', 'https://api.mainnet-beta.solana.com');
const defaultSlippage = getSessionStorageItem('slippage', 1);
const defaultPriorityFee = getSessionStorageItem('priorityFee', 0.01);
const username = getSessionStorageItem('username', '');
const userPublicKey = getSessionStorageItem('userPublicKey', '');
const isConnected =  getSessionStorageItem('isConnected', false);
const userData = JSON.parse(getSessionStorageItem('userData', null)); 

export const store = createStore({
  state() {
    return {
      autoConnect: true,
      isSidebarVisible: true,
      isConnected: isConnected,
      rpcUrl: defaultRpcUrl,
      slippage: defaultSlippage,
      priorityFee: defaultPriorityFee,
      username: username,
      userPublicKey: userPublicKey,
      userData: userData,
    };
  },

  mutations: {
    setAutoConnect(state) {
      state.autoConnect = !state.autoConnect;
    },
    setIsConnected(state, isConnected) {
      if (typeof window !== 'undefined' && sessionStorage) {
        sessionStorage.setItem('isConnected', isConnected);
      }
      state.isConnected = isConnected;
    },
    setSidebarVisible(state, visible) {
      state.isSidebarVisible = visible;
    },
    setRpcUrl(state, rpcUrl) {
      if (rpcUrl.startsWith('http://') || rpcUrl.startsWith('https://')) {
        if (typeof window !== 'undefined' && sessionStorage) {
          sessionStorage.setItem('rpcUrl', rpcUrl);
        }
        state.rpcUrl = rpcUrl;
      } else {
        console.error('Invalid RPC URL:', rpcUrl);
        state.rpcUrl = defaultRpcUrl;
      }
    },
    setUsername(state, username) {
      if (typeof window !== 'undefined' && sessionStorage) {
        sessionStorage.setItem('username', username);
      }
      state.username = username;
    },
    setUserPublicKey(state, userPublicKey) {
      if (typeof window !== 'undefined' && sessionStorage) {
        sessionStorage.setItem('userPublicKey', userPublicKey);
      }
      state.userPublicKey = userPublicKey;
    },
    setSlippage(state, slippage) {
      if (typeof window !== 'undefined' && sessionStorage) {
        sessionStorage.setItem('slippage', slippage);
      }
      state.slippage = slippage;
    },
    setPriorityFee(state, priorityFee) {
      if (typeof window !== 'undefined' && sessionStorage) {
        sessionStorage.setItem('priorityFee', priorityFee);
      }
      state.priorityFee = priorityFee;
    },
    setUserData(state, userData) { // Add mutation to set user data
      if (typeof window !== 'undefined' && sessionStorage) {
        sessionStorage.setItem('userData', JSON.stringify(userData));
      }
      state.userData = userData;
    },
    resetToDefaultSettings(state) {
      if (typeof window !== 'undefined' && sessionStorage) {
        sessionStorage.removeItem('rpcUrl');
        sessionStorage.removeItem('slippage');
        sessionStorage.removeItem('priorityFee');
        sessionStorage.removeItem('username');
        sessionStorage.removeItem('userPublicKey');
        sessionStorage.removeItem('isConnected');
        sessionStorage.removeItem('userData');
      }
      state.rpcUrl = defaultRpcUrl;
      state.slippage = defaultSlippage;
      state.priorityFee = defaultPriorityFee;
      state.username = '';
      state.userPublicKey = '';
      state.isConnected = false;
      state.userData = null; 
    }
  },

  actions: {
    handleWalletConnection({ commit }, isConnected) {
      commit('setIsConnected', isConnected);
    },
    handleIsSidebarVisible({ commit }, visible) {
      commit('setSidebarVisible', visible);
    },
    handleRpcUrlChange({ commit }, rpcUrl) {
      commit('setRpcUrl', rpcUrl);
    },
    handleSlippageChange({ commit }, slippage) {
      commit('setSlippage', slippage);
    },
    handlePriorityFeeChange({ commit }, priorityFee) {
      commit('setPriorityFee', priorityFee);
    },
    handleUsernameChange({ commit }, username) {
      commit('setUsername', username);
    },
    handleUserDataChange({ commit }, userdata) {
      commit('setUserdata', userdata);
    },
    handleUserPublicKeyChange({ commit }, userPublicKey) {
      commit('setUserPublicKey', userPublicKey);
    },
    resetSettingsToDefault({ commit }) {
      commit('resetToDefaultSettings');
    },
    async fetchAndStoreUserData({ commit, state }, id) {
      if (!state.userData) { // Only fetch if userData is not already set
        try {
          const data = await fetchUserData(id);
          commit('setUserData', data);
        } catch (error) {
          console.error('Error fetching user data in store:', error);
        }
      }
    }
  },
});
