<script setup>
import { ref, onMounted, computed } from 'vue';
import axios from 'axios';
import { socket } from '@/helper';

const tradeEvents = ref({});

const isHovered = ref(false);

const tradeData = async () => {
   try {
    const response = await axios.get(`http://localhost:3000/api/trades?page=1&limit=10`);
    if (response.data.response.length === 0) {
      return;
    }
    tradeEvents.value = response.data.response;
   } catch (error) {
    
    return;
   }
};

const marqueeItems = computed(() => {
  return tradeEvents.value.slice(0, 5); // Limit to 5 items
});

onMounted(() => {
    tradeData();

    socket.on('tradeEvent', (data) => {
        console.log('tradeEvent', data);

        // Ensure tradeEvents.value and user_info are initialized
        if (!tradeEvents.value) {
            tradeEvents.value = [];
        }

        // Create a new object for the current trade event
        const newEvent = {
            ...data, // Spread in all the data properties from the socket event
            user_info: {
                username: data.username || '',
                profile_image: data.user_image || '',
            }
        };

        // Push the new event into the tradeEvents array
        tradeEvents.value.push(newEvent);
    });
});
</script>

<template>
    <div v-if="tradeEvents.length > 4">
      <div class="relative flex overflow-x-hidden text-sm text-gray-800 font-light bg-gray-200 group"
      @mouseenter="isHovered = true"
      @mouseleave="isHovered = false">
        <!-- First Marquee -->
        <div :class="{
          'animate-marquee': !isHovered,
          'animate-marqueePaused': isHovered,
        }"
        class="py-2 whitespace-nowrap flex">
          <span class="flex gap-1 items-center border-r border-gray-800 px-8" v-for="(item, index) in marqueeItems" :key="index">
          
            <router-link :to="'/profile/' + item.user" exact class="hover:underline">
              <span class="">
                {{ item.user === item.user_info.username ? item.user.substring(0, 5) : item.user_info.username }}
              </span>
            </router-link>
            <span>
              <span v-if="item.isBuy === true" class="text-gray-800 bg-limen rounded-full px-2 py-[1px]">bought</span>
              <span v-else class="text-gray-800 bg-red-500 rounded-full px-2 py-[1px]">sold</span>
          </span>
            <span>{{ parseFloat(item.solAmount).toFixed(4) }}</span>
            <span class="mr-1">SOL of</span>
            <router-link class="hover:underline flex items-center" :to="'/token/' + item.mintAddress" exact>
              <div class="w-6">
                  <img
                  crossorigin="anonymous"
                  alt=""
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  class="h-5 w-5 rounded"
                  :src="item.token_image"
                  style="color: transparent; display: block;"
                  />
              </div>
              <span class="ml-1 text-base font-light">{{ item.symbol }}</span>
            </router-link>
          </span>
        </div>
    
        <!-- Second Marquee (Mirrored) -->
        <div class="absolute top-0 left-[15px] py-2 animate-marquee2 whitespace-nowrap flex"
          :class="{
              'animate-marquee2': !isHovered,
              'animate-marquee2Paused': isHovered,
          }">
          <span class="flex gap-1 items-center border-r border-gray-900 px-8" v-for="(item, index) in marqueeItems" :key="'mirror-' + index">
        
            <router-link :to="'/profile/' + item.user" exact class="hover:underline">
              <span class="">
                {{ item.user === item.user_info.username ? item.user.substring(0, 5) : item.user_info.username }}
              </span>
            </router-link>
            <span>
              <span v-if="item.isBuy === true" class="text-gray-800 bg-limen rounded-full px-2 py-[1px]">bought</span>
              <span v-else class="text-gray-800 bg-red-500 rounded-full px-2 py-[1px]">sold</span>
            </span>
            <span>{{ parseFloat(item.solAmount).toFixed(4) }}</span>
            <span class="mr-1">SOL of</span>
            <router-link class="hover:underline flex items-center" :to="'/' + item.mintAddress" exact>
              <div class="w-6">
                  <img
                  crossorigin="anonymous"
                  alt=""
                  loading="lazy"
                  width="20"
                  height="20"
                  decoding="async"
                  class="h-5 w-5 rounded"
                  :src="item.token_image"
                  style="color: transparent; display: block;"
                  />
              </div>
              <span class="ml-1 text-base font-light">{{ item.symbol }}</span>
            </router-link>
          </span>
        </div>
      </div>
    </div>
  </template>