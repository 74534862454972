"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSPLBalance = void 0;
const web3_js_1 = require("@solana/web3.js");
const spl_token_1 = require("@solana/spl-token");
const getSPLBalance = async (connection, mintAddress, pubKey, allowOffCurve = false) => {
    try {
        const ata = (0, spl_token_1.getAssociatedTokenAddressSync)(new web3_js_1.PublicKey(mintAddress), pubKey, allowOffCurve, spl_token_1.TOKEN_PROGRAM_ID);
        const balance = await connection.getTokenAccountBalance(ata, "confirmed");
        return balance.value.uiAmount;
    }
    catch (e) {
        console.error(e);
    }
    return '0';
};
exports.getSPLBalance = getSPLBalance;
