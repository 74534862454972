import { createRouter, createWebHistory, createMemoryHistory } from "vue-router";
import { useHead } from '@vueuse/head';
const isServer = typeof window === 'undefined';

const routes = [
  {
    path: "/",
    redirect: "/all",
    name: "home",
    component: () => import("../components/board/HomePage.vue"),
    meta: { hideNav: true }
  },
  {
    path: "/all",
    name: "all",
    component: () => import("../components/board/HomePage.vue"),
    meta: { hideNav: true, title: "WenMoon - Terminal" }
  },
  {
    path: "/profile/:id",
    name: "profile",
    component: () => import("../views/pages/profilePage.vue"),
    meta: { hideNav: true }
  },
  {
    path: "/create",
    name: "create",
    component: () => import("../views/launchpad/apeFun.vue"),
    meta: { hideFooter: true }

  },
  {
    path: "/token/:id",
    name: "token",
    component: () => import("../views/launchpad/tradeFun.vue"),
    meta: { hideNav: true }
  },
  {
    path: "/:pathMatch(.*)*",
    name: "Page404",
    component: () => import("../views/layouts/error/404-error.vue"),
    meta: { title: "Oops! 404", hideNav: true },
  },
  {
    path: "/500",
    name: "Page500",
    component: () => import("../views/layouts/error/500-error.vue"),
    meta: { title: "Server internal Error", hideNav: true },
  },
];

const router = createRouter({
  history: isServer ? createMemoryHistory() : createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition; // Retain the scroll position if saved
    } else if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth', // Optional: Enable smooth scrolling
      };
    } else {
      return { top: 0 }; // Scroll to the top of the page
    }
  },
});

router.beforeEach((to, from, next) => {
  const head = to.meta.head || {};
  useHead(head);
  next();
});

export default router;