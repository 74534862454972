<script setup>
import store from './vuex';
import { computed } from 'vue';
import 'solana-wallets-vue/styles.css';
import 'vue3-toastify/dist/index.css';
import { initWallet } from 'solana-wallets-vue';
import {

  LedgerWalletAdapter,
} from "@solana/wallet-adapter-wallets";

const wallets = [
  new LedgerWalletAdapter(),
];

const isSidebarVisible = computed(() => store.state.isSidebarVisible);
console.log('isSidebarVisible', store.state.isSidebarVisible);
initWallet({ wallets, autoConnect: store.state.autoConnect });

</script>
<template>
  <div class="flex flex-col w-full bg-secondary transition-colors relative font-super flex-grow">
      
      <LoginHeader />
    
      <div class="flex flex-grow">
        <div v-if="!$route.meta.hideNav"
          class="hidden lg:block w-[240px] w-sidebar bg-white border-r-2 lg:z-0 z-20 overflow-auto lg:relative fixed p-4"
        >
        <SidebarVue/>
        </div>  
        <div class="grid w-full">
            <router-view :key="$route.fullPath" v-slot="{ Component }">
              <component :is="Component" />
            </router-view>
        </div>
      </div>
      <FooterVue v-if="!$route.meta.hideFooter"/>
  </div>
  
</template>


<script>
  // Vue components
  import LoginHeader from '../src/components/LoginHeader.vue';
  import FooterVue from '../src/components/FooterVue.vue';
  import SidebarVue from '../src/components/sidebarVue.vue';
  export default {
    name: "WenMoon",

    data() {
      return {
        sidebar: false,
      };
    },

    components: {
    LoginHeader,
    FooterVue,
    SidebarVue
    },

    
    
  };
</script>
