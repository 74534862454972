import * as anchor from "@coral-xyz/anchor";
import { PublicKey } from "@solana/web3.js";
import { LAUNCHPAD_PROGRAM_ID } from "./util";

export const BONDING_CURVE_SEED = Buffer.from(
  anchor.utils.bytes.utf8.encode("bonding-curve")
);


export async function getBondingCurveAddress(
  mint: PublicKey,
) {
  const [bondingCurvePDA] = PublicKey.findProgramAddressSync(
    [BONDING_CURVE_SEED, mint.toBuffer()],
    LAUNCHPAD_PROGRAM_ID
  );
  return bondingCurvePDA;
}
