<script setup>
import { defineEmits, onMounted, onBeforeUnmount } from 'vue';
import { Icon } from '@iconify/vue';

const emit = defineEmits(['close-modal']);

const closeModal = () => {
  emit('close-modal'); // Emit event to close modal
};

const lockBodyScroll = () => {
  document.body.style.overflow = 'hidden';
};

const unlockBodyScroll = () => {
  document.body.style.overflow = '';
};

onMounted(() => {
  lockBodyScroll(); // Disable body scroll when modal is opened
});

onBeforeUnmount(() => {
  unlockBodyScroll(); // Enable body scroll when modal is closed
});
</script>

<template>
  <div>
    <teleport to="body">
      <transition name="slide-up">
        <div class="modal-overlay p-5 z-[9999] bg-black/90 overflow-hidden">
          <div
            class="right-0 left-0 absolute mx-auto flex max-w-[450px] min-h-screen top-[6rem] sm:top-0 sm:items-center overflow-hidden max-h-[300px]"
          >
            <form>
              <div class="relative font-super modal bg-primary w-full shadow rounded-md text-gray-800 p-5 w-[400px]">
                <div class="text-center">
                  <div class="flex flex-col space-y-1.5 mb-4 sm:text-left text-center">
                    <h2 class="text-lg font-semibold leading-none tracking-tight text-center">
                      How it works
                    </h2>
                  </div>
                  <div class="text-sm mb-4">
                    Wenmoon prevents rugs by making sure that all created tokens are safe. Each coin on wenmoon is a
                    <span class="text-green-600 bold">fair-launch</span> with
                    <span class="text-blue-600">no presale</span> and
                    <span class="text-orange-600">no team allocation.</span>
                  </div>
                  <div class="text-gray-800 text-sm flex flex-col gap-2 lg:gap-4">
                    <div class="text-gray-800">
                      <span class="font-bold">Step 1:</span> Pick a coin that you like
                    </div>
                    <div class="text-gray-800">
                      <span class="font-bold">Step 2:</span> Buy the coin on the bonding curve
                    </div>
                    <div class="text-gray-800">
                      <span class="font-bold">Step 3:</span> Sell at any time to lock in your profits or losses
                    </div>
                    <div class="text-gray-800">
                      <span class="font-bold">Step 4:</span> When enough people buy on the bonding curve it reaches a market cap of $69k
                    </div>
                    <div class="text-gray-800">
                      <span class="font-bold">Step 5:</span> $12k of liquidity is then deposited in raydium and burned
                    </div>
                  </div>
                </div>
                <div class="flex justify-center gap-5 mt-8 flex-col sm:flex-row">
                  <router-link
                    to="/create"
                    exact
                    @click="closeModal"
                    class="inline-flex relative items-center justify-center whitespace-nowrap text-sm font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 group w-full"
                  >
                    <span
                      class="relative z-10 flex justify-center h-10 px-4 py-2 hover:bg-limen/40 w-full rounded-md border-2 border-gray-900 hover:border-2 hover:border-limen transition-all duration-300 ease-out transform -translate-y-1 -translate-x-1 bg-limen group-hover:translate-y-0 group-hover:translate-x-0"
                    >
                      <span class="relative text-gray-800 font-bold">Launch Token</span>
                    </span>
                    <span class="absolute bottom-0 right-0 w-full h-10 bg-gray-900 rounded-lg transition-all duration-300 ease-out group-hover:bg-white"></span>
                  </router-link>
                </div>
                <div class="absolute top-2 right-2">
                  <button @click="closeModal">
                    <Icon icon="fa:close" class="text-sm" />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </transition>
    </teleport>
  </div>
</template>
