"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IDL = void 0;
exports.IDL = {
    "version": "0.1.0",
    "name": "curve_launchpad",
    "instructions": [
        {
            "name": "initialize",
            "accounts": [
                {
                    "name": "authority",
                    "isMut": true,
                    "isSigner": true
                },
                {
                    "name": "global",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "systemProgram",
                    "isMut": false,
                    "isSigner": false
                }
            ],
            "args": []
        },
        {
            "name": "create",
            "accounts": [
                {
                    "name": "mint",
                    "isMut": true,
                    "isSigner": true
                },
                {
                    "name": "creator",
                    "isMut": true,
                    "isSigner": true
                },
                {
                    "name": "mintAuthority",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "bondingCurve",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "bondingCurveTokenAccount",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "global",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "metadata",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "systemProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "tokenProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "associatedTokenProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "tokenMetadataProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "rent",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "eventAuthority",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "program",
                    "isMut": false,
                    "isSigner": false
                }
            ],
            "args": [
                {
                    "name": "name",
                    "type": "string"
                },
                {
                    "name": "symbol",
                    "type": "string"
                },
                {
                    "name": "uri",
                    "type": "string"
                }
            ]
        },
        {
            "name": "buy",
            "accounts": [
                {
                    "name": "user",
                    "isMut": true,
                    "isSigner": true
                },
                {
                    "name": "global",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "feeRecipient",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "mint",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "bondingCurve",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "bondingCurveTokenAccount",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "userTokenAccount",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "systemProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "tokenProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "eventAuthority",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "program",
                    "isMut": false,
                    "isSigner": false
                }
            ],
            "args": [
                {
                    "name": "tokenAmount",
                    "type": "u64"
                },
                {
                    "name": "maxSolCost",
                    "type": "u64"
                }
            ]
        },
        {
            "name": "sell",
            "accounts": [
                {
                    "name": "user",
                    "isMut": true,
                    "isSigner": true
                },
                {
                    "name": "global",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "feeRecipient",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "mint",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "bondingCurve",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "bondingCurveTokenAccount",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "userTokenAccount",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "systemProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "tokenProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "eventAuthority",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "program",
                    "isMut": false,
                    "isSigner": false
                }
            ],
            "args": [
                {
                    "name": "tokenAmount",
                    "type": "u64"
                },
                {
                    "name": "minSolOutput",
                    "type": "u64"
                }
            ]
        },
        {
            "name": "withdraw",
            "accounts": [
                {
                    "name": "user",
                    "isMut": true,
                    "isSigner": true
                },
                {
                    "name": "global",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "mint",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "lastWithdraw",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "bondingCurve",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "bondingCurveTokenAccount",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "userTokenAccount",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "associatedTokenProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "systemProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "tokenProgram",
                    "isMut": false,
                    "isSigner": false
                }
            ],
            "args": []
        },
        {
            "name": "setParams",
            "accounts": [
                {
                    "name": "global",
                    "isMut": true,
                    "isSigner": false
                },
                {
                    "name": "user",
                    "isMut": false,
                    "isSigner": true
                },
                {
                    "name": "systemProgram",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "eventAuthority",
                    "isMut": false,
                    "isSigner": false
                },
                {
                    "name": "program",
                    "isMut": false,
                    "isSigner": false
                }
            ],
            "args": [
                {
                    "name": "feeRecipient",
                    "type": "publicKey"
                },
                {
                    "name": "withdrawAuthority",
                    "type": "publicKey"
                },
                {
                    "name": "initialVirtualTokenReserves",
                    "type": "u64"
                },
                {
                    "name": "initialVirtualSolReserves",
                    "type": "u64"
                },
                {
                    "name": "initialRealTokenReserves",
                    "type": "u64"
                },
                {
                    "name": "initalTokenSupply",
                    "type": "u64"
                },
                {
                    "name": "feeBasisPoints",
                    "type": "u64"
                }
            ]
        }
    ],
    "accounts": [
        {
            "name": "bondingCurve",
            "type": {
                "kind": "struct",
                "fields": [
                    {
                        "name": "virtualSolReserves",
                        "type": "u64"
                    },
                    {
                        "name": "virtualTokenReserves",
                        "type": "u64"
                    },
                    {
                        "name": "realSolReserves",
                        "type": "u64"
                    },
                    {
                        "name": "realTokenReserves",
                        "type": "u64"
                    },
                    {
                        "name": "tokenTotalSupply",
                        "type": "u64"
                    },
                    {
                        "name": "complete",
                        "type": "bool"
                    }
                ]
            }
        },
        {
            "name": "global",
            "type": {
                "kind": "struct",
                "fields": [
                    {
                        "name": "authority",
                        "type": "publicKey"
                    },
                    {
                        "name": "initialized",
                        "type": "bool"
                    },
                    {
                        "name": "feeRecipient",
                        "type": "publicKey"
                    },
                    {
                        "name": "initialVirtualTokenReserves",
                        "type": "u64"
                    },
                    {
                        "name": "initialVirtualSolReserves",
                        "type": "u64"
                    },
                    {
                        "name": "initialRealTokenReserves",
                        "type": "u64"
                    },
                    {
                        "name": "initialRealSolReserves",
                        "type": "u64"
                    },
                    {
                        "name": "initialTokenSupply",
                        "type": "u64"
                    },
                    {
                        "name": "feeBasisPoints",
                        "type": "u64"
                    },
                    {
                        "name": "withdrawAuthority",
                        "type": "publicKey"
                    }
                ]
            }
        },
        {
            "name": "lastWithdraw",
            "type": {
                "kind": "struct",
                "fields": [
                    {
                        "name": "lastWithdrawTimestamp",
                        "type": "i64"
                    }
                ]
            }
        }
    ],
    "events": [
        {
            "name": "CreateEvent",
            "fields": [
                {
                    "name": "name",
                    "type": "string",
                    "index": false
                },
                {
                    "name": "symbol",
                    "type": "string",
                    "index": false
                },
                {
                    "name": "uri",
                    "type": "string",
                    "index": false
                },
                {
                    "name": "mint",
                    "type": "publicKey",
                    "index": false
                },
                {
                    "name": "bondingCurve",
                    "type": "publicKey",
                    "index": false
                },
                {
                    "name": "creator",
                    "type": "publicKey",
                    "index": false
                }
            ]
        },
        {
            "name": "TradeEvent",
            "fields": [
                {
                    "name": "mint",
                    "type": "publicKey",
                    "index": false
                },
                {
                    "name": "solAmount",
                    "type": "u64",
                    "index": false
                },
                {
                    "name": "tokenAmount",
                    "type": "u64",
                    "index": false
                },
                {
                    "name": "isBuy",
                    "type": "bool",
                    "index": false
                },
                {
                    "name": "user",
                    "type": "publicKey",
                    "index": false
                },
                {
                    "name": "timestamp",
                    "type": "i64",
                    "index": false
                },
                {
                    "name": "virtualSolReserves",
                    "type": "u64",
                    "index": false
                },
                {
                    "name": "virtualTokenReserves",
                    "type": "u64",
                    "index": false
                },
                {
                    "name": "realSolReserves",
                    "type": "u64",
                    "index": false
                },
                {
                    "name": "realTokenReserves",
                    "type": "u64",
                    "index": false
                }
            ]
        },
        {
            "name": "CompleteEvent",
            "fields": [
                {
                    "name": "user",
                    "type": "publicKey",
                    "index": false
                },
                {
                    "name": "mint",
                    "type": "publicKey",
                    "index": false
                },
                {
                    "name": "bondingCurve",
                    "type": "publicKey",
                    "index": false
                },
                {
                    "name": "timestamp",
                    "type": "i64",
                    "index": false
                }
            ]
        },
        {
            "name": "SetParamsEvent",
            "fields": [
                {
                    "name": "feeRecipient",
                    "type": "publicKey",
                    "index": false
                },
                {
                    "name": "withdrawAuthority",
                    "type": "publicKey",
                    "index": false
                },
                {
                    "name": "initialVirtualTokenReserves",
                    "type": "u64",
                    "index": false
                },
                {
                    "name": "initialVirtualSolReserves",
                    "type": "u64",
                    "index": false
                },
                {
                    "name": "initialRealTokenReserves",
                    "type": "u64",
                    "index": false
                },
                {
                    "name": "initialTokenSupply",
                    "type": "u64",
                    "index": false
                },
                {
                    "name": "feeBasisPoints",
                    "type": "u64",
                    "index": false
                }
            ]
        }
    ],
    "errors": [
        {
            "code": 6000,
            "name": "AlreadyInitialized",
            "msg": "Global Already Initialized"
        },
        {
            "code": 6001,
            "name": "NotInitialized",
            "msg": "Global Not Initialized"
        },
        {
            "code": 6002,
            "name": "InvalidAuthority",
            "msg": "Invalid Authority"
        },
        {
            "code": 6003,
            "name": "BondingCurveComplete",
            "msg": "Bonding Curve Complete"
        },
        {
            "code": 6004,
            "name": "BondingCurveNotComplete",
            "msg": "Bonding Curve Not Complete"
        },
        {
            "code": 6005,
            "name": "InsufficientTokens",
            "msg": "Insufficient Tokens"
        },
        {
            "code": 6006,
            "name": "InsufficientSOL",
            "msg": "Insufficient SOL"
        },
        {
            "code": 6007,
            "name": "MaxSOLCostExceeded",
            "msg": "Max SOL Cost Exceeded"
        },
        {
            "code": 6008,
            "name": "MinSOLOutputExceeded",
            "msg": "Min SOL Output Exceeded"
        },
        {
            "code": 6009,
            "name": "MinBuy",
            "msg": "Min buy is 1 Token"
        },
        {
            "code": 6010,
            "name": "MinSell",
            "msg": "Min sell is 1 Token"
        },
        {
            "code": 6011,
            "name": "InvalidFeeRecipient",
            "msg": "Invalid Fee Recipient"
        },
        {
            "code": 6012,
            "name": "InvalidWithdrawAuthority",
            "msg": "Invalid Withdraw Authority"
        }
    ]
};
