"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AMM = void 0;
class AMM {
    virtualSolReserves;
    virtualTokenReserves;
    realSolReserves;
    realTokenReserves;
    initialVirtualTokenReserves;
    constructor(virtualSolReserves, virtualTokenReserves, realSolReserves, realTokenReserves, initialVirtualTokenReserves) {
        this.virtualSolReserves = virtualSolReserves;
        this.virtualTokenReserves = virtualTokenReserves;
        this.realSolReserves = realSolReserves;
        this.realTokenReserves = realTokenReserves;
        this.initialVirtualTokenReserves = initialVirtualTokenReserves;
    }
    getBuyPrice(tokens) {
        const productOfReserves = this.virtualSolReserves * this.virtualTokenReserves;
        const newVirtualTokenReserves = this.virtualTokenReserves - tokens;
        const newVirtualSolReserves = (productOfReserves / newVirtualTokenReserves) + 1n;
        const amountNeeded = newVirtualSolReserves - this.virtualSolReserves;
        return amountNeeded;
    }
    getBuyTokens(solAmount) {
        const productOfReserves = this.virtualSolReserves * this.virtualTokenReserves;
        const newVirtualSolReserves = this.virtualSolReserves + solAmount;
        const newVirtualTokenReserves = productOfReserves / newVirtualSolReserves;
        const tokensReceived = this.virtualTokenReserves - newVirtualTokenReserves;
        return tokensReceived;
    }
    applyBuy(token_amount) {
        const final_token_amount = token_amount > this.realTokenReserves ? this.realTokenReserves : token_amount;
        const sol_amount = this.getBuyPrice(final_token_amount);
        this.virtualTokenReserves = this.virtualTokenReserves - final_token_amount;
        this.realTokenReserves = this.realTokenReserves - final_token_amount;
        this.virtualSolReserves = this.virtualSolReserves + sol_amount;
        this.realSolReserves = this.realSolReserves + sol_amount;
        return {
            token_amount: final_token_amount,
            sol_amount: sol_amount
        };
    }
    applyBuyTokens(sol_amount) {
        const final_sol_amount = sol_amount > this.realSolReserves ? this.realSolReserves : sol_amount;
        const token_amount = this.getBuyTokens(final_sol_amount);
        this.virtualSolReserves += final_sol_amount;
        this.realSolReserves += final_sol_amount;
        this.virtualTokenReserves -= token_amount;
        this.realTokenReserves -= token_amount;
        return {
            token_amount: token_amount,
            sol_amount: final_sol_amount
        };
    }
    applySell(token_amount) {
        this.virtualTokenReserves = this.virtualTokenReserves + token_amount;
        this.realTokenReserves = this.realTokenReserves + token_amount;
        const sell_price = this.getSellPrice(token_amount);
        this.virtualSolReserves = this.virtualSolReserves - sell_price;
        this.realSolReserves = this.realSolReserves - sell_price;
        return {
            token_amount: token_amount,
            sol_amount: sell_price
        };
    }
    getSellPrice(tokens) {
        const scaling_factor = this.initialVirtualTokenReserves;
        const token_sell_proportion = (tokens * scaling_factor) / this.virtualTokenReserves;
        const sol_received = (this.virtualSolReserves * token_sell_proportion) / scaling_factor;
        return sol_received < this.realSolReserves ? sol_received : this.realSolReserves;
    }
}
exports.AMM = AMM;
