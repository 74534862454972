<script setup>
import { ref, defineEmits, defineProps } from 'vue';
import { useWallet } from 'solana-wallets-vue'

import { socket } from '../../helper'
import store from '@/vuex';
import {Icon} from '@iconify/vue';

const { disconnect, publicKey } = useWallet();
const emit = defineEmits(['close-modal']);
const isEditModalOpen = ref(false);

const props = defineProps({
  userData: {
    type: Object,
    required: true
  },
  publicKey: {
    type: String,
    required: true
  }
});



const closeModal = () => {
  emit("close-modal"); // Emit event to close modal
};

const disconnectWallet = async () => {
    try {
        socket.emit('unsubscribeUser', publicKey.value.toString());
        await disconnect();
        store.commit('resetToDefaultSettings');
        closeModal();
    } catch (error) {
        console.error('Error disconnecting wallet:', error);
    }
};

</script>

<template>
  <div>
    <teleport to="body">
      <transition name="slide-up">
        <div
          
          class="modal-overlay p-5 z-[9999] bg-black/90"
        >
          <div
            class="container font-super justify-center mx-auto flex max-w-[450px] min-h-screen items-center"
          >
            <form class="w-full">
              <div class="relative modal bg-primary w-full shadow rounded-md text-gray-800 p-5 w-[400px]">
                <div class="flex flex-col gap-2">
                  <div class="flex gap-4 items-center mb-2 flex-col sm:flex-row">
                        <div class="min-w-20">
                            <img crossorigin="anonymous" alt="" loading="lazy" decoding="async" data-nimg="1" class="w-20 h-20 rounded-full border-2 border-gray-900 object-contain" 
                            :src="props.userData.profile_image" style="color: transparent; display: block;">
                        </div>
                        <div class="flex flex-col justify-center pl-1">
                            <div class="sono flex justify-items-start font-medium">
                              @{{ props.userData.user.substring(0,5) }}...{{ props.userData.user.slice(-5) }}</div>

                              <button @click="openEditModal" class="cursor-pointer hover:font-bold mt-3 relative w-fit group">
                                  <span class="relative z-10 flex text-nowrap items-center px-2 py-1 h-[2.35rem] overflow-hidden font-medium text-gray-800 transition-all duration-300 ease-out border-2 border-gray-900 rounded-lg bg-primary transform -translate-y-1 -translate-x-1 group-hover:translate-y-0 group-hover:translate-x-0">
                                      <span class="relative  font-bold flex gap-1 items-center">
                                          <span class="text-sm">Edit Profile</span>
                                          <Icon icon="uil:edit" class="text-md"/>
                                      </span>
                                  </span>
                                  <span class="absolute bottom-[1px] right-[1px] w-full h-[2.34rem] bg-gray-900 rounded-lg transition-all duration-300 ease-out group-hover:bg-white"></span>
                              </button>
                        </div>
                    </div>
                    <div class="text-sm w-full">
                      <div class="sono">
                        <input id="username" class="flex h-10 sono rounded-lg border-2 border-gray-900 px-3 py-2 text-sm  bg-transparent text-gray-800 outline-none w-full pl-3" :value="props.userData.user" disabled>
                      </div>
                        
                    </div>

                    <div class="pl-1 mt-2">
                      <button @click="disconnectWallet" class="cursor-pointer hover:font-bold relative w-full group">
                        <span class="relative z-10 flex text-nowrap items-center justify-center px-2 py-1 h-10 overflow-hidden font-medium text-gray-800 transition-all duration-300 ease-out border-2 border-gray-900 rounded-lg bg-primary transform -translate-y-1 -translate-x-1 group-hover:translate-y-0 group-hover:translate-x-0">
                            <span class="relative  text-sm font-bold">
                              Disconnect Wallet
                            </span>
                        </span>
                        <span class="absolute bottom-[1px] right-[1px] w-full h-10 bg-gray-900 rounded-lg transition-all duration-300 ease-out group-hover:bg-white"></span>
                      </button>
                    </div>
                </div>
                <div class="absolute top-2 right-2">
                  <button @click="closeModal">
                    <Icon icon="fa:close" class="text-sm"/>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </transition>
    </teleport>

  </div>
</template>