import * as anchor from "@coral-xyz/anchor";
import * as client from "@/functions/launchpad/amm";

import {
  Connection,
  PublicKey,
} from "@solana/web3.js";

import { getBondingCurveAddress } from "./index";
import axios from "axios";
import { CurveLaunchpad, IDL } from '../idl/curve_launchpad';
import bs58 from 'bs58';

export const MPL_PROGRAM_ID = new PublicKey("metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s");
export const LAUNCHPAD_PROGRAM_ID = new PublicKey("mLhpppsLAzx2YXir4zGabcDsR472bh5GZdQiGe6Wc6N");
export const TREASURY_ID = new PublicKey('s9ejKoacyvdJLoZipSwaPf7Pm7W3QCZ4EDDmCZTXWPj');
export const DEFUALT_INITIAL_VIRTUAL_TOKEN_RESERVE = 1_073_000_000_000_000n;
export const DEFAULT_FEE_BASIS_POINTS = 50n;
export const DEFAULT_DECIMALS = 6n;

export const calculateFee = (amount: bigint, fee: number): bigint => {
  return (amount * BigInt(fee)) / 10000n;
};

export async function calculateTokenAmount(
  connection: Connection,
  mint: string,
  solAmount: number
) {
  if (solAmount === 0) {
    return {
      tokenAmount: 0,
      tokenAmountBN: 0,
      solAmount: 0,
      solAmountBN: 0
    }
  }
  const mintAccount = new PublicKey(mint);
  
  const bondingCurvePDA = await getBondingCurveAddress(mintAccount);
  
  const currentAmm = await getAmmFromBondingCurve(connection, bondingCurvePDA);
  
  // Convert sol amount to BigInt
  let bigIntSolAmount = BigInt(solAmount * 1e9);

  // Calculate the fee
  const feeAddedSolAmount = calculateFee(bigIntSolAmount, Number(DEFAULT_FEE_BASIS_POINTS));

  // Add the fee to the original sol amount
  bigIntSolAmount = bigIntSolAmount + feeAddedSolAmount;

  // Calculate the token amount using the total sol amount (including fee)
  const tokenAmount = currentAmm.getBuyTokens(bigIntSolAmount);
  // Convert token amount to a number for easier handling
  const numberTokenAmount = Number(tokenAmount) / (10 ** Number(DEFAULT_DECIMALS));

  // Convert the fee-added sol amount back to a number for easier handling
  const feeAddedSolAmountNumber = Number(bigIntSolAmount) / 1e9;
  
  return {
    tokenAmount: numberTokenAmount,
    tokenAmountBN: tokenAmount,
    solAmount: feeAddedSolAmountNumber,
    solAmountBN: bigIntSolAmount
  };
}

export async function calculateSolForBuy(
  connection: Connection,
  mint: string,
  tokenAmount: number
) {
  console.log('tokenAmount', tokenAmount);
  if (tokenAmount === 0) {
    return {
      solAmount: 0,
      solAmountBN: 0,
      tokenAmount: 0
    }
  }
  const mintAccount = new PublicKey(mint);
  
  const bondingCurvePDA = await getBondingCurveAddress(mintAccount);
  
  const currentAmm = await getAmmFromBondingCurve(connection, bondingCurvePDA);
 
  // Convert sol amount to BigInt
  const bigIntTokenAmount = BigInt(tokenAmount * 1e6);
  console.log('bigIntTokenAmount', bigIntTokenAmount);
  const buyMaxSOLAmount = currentAmm.getBuyPrice(bigIntTokenAmount);
  console.log('buyMaxSOLAmount', buyMaxSOLAmount);
  // Calculate the fee
  const feeAddedSolAmount = calculateFee(buyMaxSOLAmount, Number(DEFAULT_FEE_BASIS_POINTS));

  // Add the fee to the original sol amount
  const bigIntSolAmount = buyMaxSOLAmount + feeAddedSolAmount;

  // Convert the fee-added sol amount back to a number for easier handling
  const feeAddedSolAmountNumber = Number(bigIntSolAmount) / 1e9;
  console.log('feeAddedSolAmountNumber', feeAddedSolAmountNumber);
  return {
    solAmount: feeAddedSolAmountNumber,
    solAmountBN: bigIntSolAmount,
    tokenAmount: tokenAmount
  };
}

export async function calculateSolAmount(
  connection: Connection,
  mint: string,
  tokenAmount: number
) {
  const mintAccount = new PublicKey(mint);
  const bondingCurvePDA = await getBondingCurveAddress(mintAccount);
  
  const currentAmm = await getAmmFromBondingCurve(connection, bondingCurvePDA);
  
  const bigIntTokenAmount = BigInt(Math.round(tokenAmount * (10 ** Number(DEFAULT_DECIMALS))));
  
  
  const sellData = currentAmm.applySell(bigIntTokenAmount);
  let minSolAmount = sellData.sol_amount;
  const fee = calculateFee(minSolAmount, Number(DEFAULT_FEE_BASIS_POINTS));
  minSolAmount = minSolAmount - fee;
  const numberSolAmount = Number(minSolAmount) / 1e9;
  return {
    solAmount: numberSolAmount,
    solAmountBN: minSolAmount,
  }
}

const virtualSolReserves: bigint = 30_000_000_000n;
const virtualTokenReserves: bigint = 1_073_000_000_000_000n;

export function getBuyPrice(tokens: bigint): bigint {
  const productOfReserves = virtualSolReserves * virtualTokenReserves;
  const newVirtualTokenReserves = virtualTokenReserves - tokens;
  const newVirtualSolReserves = (productOfReserves / newVirtualTokenReserves) + 1n;
  const amountNeeded = newVirtualSolReserves - virtualSolReserves;

  return amountNeeded;
}

export const getAmmFromBondingCurve = async (
  connection: Connection,
  bondingCurvePDA: PublicKey
) => {
  const program = new anchor.Program(IDL, LAUNCHPAD_PROGRAM_ID, {
    connection
  });
  
  const bondingCurveAccount = await program.account.bondingCurve.fetch(
    bondingCurvePDA,
    'confirmed'
  );
  
  return ammFromBondingCurve(
    bondingCurveAccount,
    DEFUALT_INITIAL_VIRTUAL_TOKEN_RESERVE
  );
};

export const ammFromBondingCurve = (
  bondingCurveAccount: anchor.IdlAccounts<CurveLaunchpad>["bondingCurve"] | null,
  initialVirtualTokenReserves: bigint
) => {
  if (!bondingCurveAccount) throw new Error("Bonding curve account not found");

  return new client.AMM(
    BigInt(bondingCurveAccount.virtualSolReserves.toString()),
    BigInt(bondingCurveAccount.virtualTokenReserves.toString()),
    BigInt(bondingCurveAccount.realSolReserves.toString()),
    BigInt(bondingCurveAccount.realTokenReserves.toString()),
    initialVirtualTokenReserves
  );
};

export async function isValidAddress(address: string) {
  try {
    // Convert the decoded bytes to a PublicKey object
    const publicKey = new PublicKey(address);

    return PublicKey.isOnCurve(publicKey.toBytes());
  } catch (error) {
    // If decoding fails or PublicKey is not on the curve, the address is invalid
    return false;
  }
}

export async function signAndVerifyLogin(
  signMessage: any,
  publicKey: PublicKey,
  token: string,
) {
  try {
      const uniqueId = Date.now();
      const text = `Sign into wenmoon.gg: ${uniqueId}`;
      const message = new TextEncoder().encode(text);
 
      const signature = await signMessage.value(message);
     
      // Convert the Uint8Array signature to a base58-encoded string
      const signatureBase58 = bs58.encode(signature);

      const verifyWithBackend = await axios.post('http://localhost:3000/connect', {
          message: text,
          signature: signatureBase58,
          publicKey: publicKey.toBase58(),
          token: token
      });

      if (!verifyWithBackend.data.success) {
          return false;
      }
      
      return verifyWithBackend.data;
  } catch (error) {
      console.error('Error signing and verifying message:', error);
      return false;
  }
}

export const unixHelper = (timestamp: any) => {
  const date = new Date(timestamp * 1000); // Convert Unix timestamp to milliseconds
  const month = (date.getMonth() + 1).toString(); // Months are zero-based
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear().toString().slice(-2); // Get last two digits of the year

  return `${month}/${day}/${year}`;
};

export const preciseUnix = (timestamp: number) => {
  const now = Date.now() / 1000; // Get current time in Unix format (seconds)
  const secondsAgo = now - timestamp; // Calculate the difference in seconds

  const secondsInMinute = 60;
  const secondsInHour = secondsInMinute * 60;
  const secondsInDay = secondsInHour * 24;
  const secondsInWeek = secondsInDay * 7;

  if (secondsAgo < secondsInMinute) {
    return `${Math.floor(secondsAgo)} second${Math.floor(secondsAgo) !== 1 ? 's' : ''} ago`;
  } else if (secondsAgo < secondsInHour) {
    const minutes = Math.floor(secondsAgo / secondsInMinute);
    return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
  } else if (secondsAgo < secondsInDay) {
    const hours = Math.floor(secondsAgo / secondsInHour);
    return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
  } else if (secondsAgo < secondsInWeek) {
    const days = Math.floor(secondsAgo / secondsInDay);
    return `${days} day${days !== 1 ? 's' : ''} ago`;
  } else {
    const date = new Date(timestamp * 1000); // Convert Unix timestamp to milliseconds
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear().toString(); // Get full year
    return `${month}/${day}/${year}`;
  }
};

export const fetchUserData = async (id: string) => {
  try {
   
    const response = await axios.get(`http://localhost:3000/user/${id}`);
    
    return response.data;
  } catch (error) {
    console.error('Error fetching user data:', error);
    return {};
  }
}

export const unixHelperWithTime = (timestamp: any) => {
  const date = new Date(timestamp * 1000); // Convert Unix timestamp to milliseconds
  const options: Intl.DateTimeFormatOptions = {
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true,
  };
  return date.toLocaleString('en-US', options);
};

export async function percentageBalance(
  amount: number,
  percent: number
) {
  return (amount * percent);
} 

export const handleUserChanges = async (
  props: any,
  token: string,
  selectedFile?: any,
  username?: string,
  bio?: string
) => {
  const userData = props.userData; 
  // compare prop username to username
  if (userData.username === username) {
    username = '';
  }
  const formData = new FormData();

  formData.append('publicKey', userData.user);
  formData.append('username', username || '');
  formData.append('bio', bio || '');
  formData.append('token', token);
  if (selectedFile) {
      formData.append('image', selectedFile);
  }

  try {
      const response = await axios.post('http://localhost:3000/update-user', formData);
      if (response.data.success) {
          return response.data;
         
      } else {
         console.error('Error updating user:', response.data.error);
         return response.data;
      }
  } catch (error) {
      console.error('Error updating user:', error);
  }
}

// check if user follows another user
export const checkFollow = async (user: string, following: string) => {
  try {
    const response = await axios.get(`http://localhost:3000/api/check-following?publicKey=${user}&userToFollow=${following}`);
    
    return response.data;
  } catch (error) {
   
    return false;
  }
}