import {
  Connection,
  PublicKey,
} from "@solana/web3.js";
import { TOKEN_PROGRAM_ID, getAssociatedTokenAddressSync } from "@solana/spl-token";


export const getSPLBalance = async (
  connection: Connection,
  mintAddress: PublicKey,
  pubKey: PublicKey,
  allowOffCurve: boolean = false
) => {
  try {
  
    const ata = getAssociatedTokenAddressSync(new PublicKey(mintAddress), pubKey, allowOffCurve, TOKEN_PROGRAM_ID);

    const balance = await connection.getTokenAccountBalance(ata, "confirmed");

    return balance.value.uiAmount;
  } catch (e) {
    console.error(e);
  }
  return '0';
}