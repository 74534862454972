<script setup>
import { WalletMultiButton, useWallet } from 'solana-wallets-vue';
import { watch, onMounted, ref, nextTick, onBeforeUnmount, computed } from 'vue';
import { signAndVerifyLogin } from '@/functions/utils';
import store from '../vuex';
import { socket } from '@/helper';
import { useReCaptcha } from "vue-recaptcha-v3";
import userModal from "@/components/user/userModal.vue";
import marqueeHeader from "@/components/extras/marqueeHeader.vue";
import howTo from "@/components/modals/howTo.vue";
import { useRoute } from 'vue-router';
import { Icon } from '@iconify/vue';

const route = useRoute();
const isClient = typeof window !== 'undefined'; 
const { publicKey, signMessage, connected } = isClient ? useWallet() : { publicKey: ref(null), signMessage: null, connected: ref(false) };
const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();

const isUserModalOpen = ref(false);
const userData = ref([]);
const isHowToModalOpen = ref(false);
const isLoading = ref(false);
const isLoginHappening = ref(false);

const showHeader = computed(() => {
  let isSmallScreen = false; // Default value for SSR

  if (isClient) {
    isSmallScreen = window.innerWidth <= 1024;
  }

  const isSpecificRoute = route.path === '/create' || route.path === '/all' || route.path.startsWith('/profile');

  // Show header on all routes if screen width is more than 1024px, otherwise only on specific routes
  return !isSmallScreen || isSpecificRoute;
});

const openUserModal = () => {
  isUserModalOpen.value = true;
};

const openWalletModal = async () => {
  if (isClient) { // Ensure we're in the browser
    await nextTick(); // Wait for DOM to be updated
    const button = document.querySelector('button.swv-button.swv-button-trigger'); // Adjust selector
    if (button) {
      button.click();
    } else {
      console.error('Button not found');
    }
  }
};

// Function to handle login and verification
const login = async () => {
  if (isLoginHappening.value) {
    console.log('Login already in progress');
    return;
  }

  isLoginHappening.value = true;
  isLoading.value = true;

  if (!publicKey.value) {
    isLoading.value = false;
    isLoginHappening.value = false;
    return;
  }
  
  await recaptchaLoaded();
  const token = await executeRecaptcha("login");

  const isVerified = await signAndVerifyLogin(signMessage, publicKey.value, token);

  if (isVerified.success) {
    store.commit('setIsConnected', true);
    await store.dispatch('fetchAndStoreUserData', publicKey.value.toString());
    
    userData.value = store.state.userData;

    if (userData.value) {
      store.commit('setUsername', userData.value.username);
      store.commit('setUserPublicKey', userData.value.user);
    } 
    
    socket.emit('subscribeUser', publicKey.value.toString());
    console.log('isVerified ✅', isVerified);
  } else {
    store.commit('resetToDefaultSettings');
  }

  isLoading.value = false;
  isLoginHappening.value = false;
};

const checkLoginStatus = async () => {
  if (isLoginHappening.value) {
    console.log('Login already in progress');
    return;
  }
  console.log('connected', store.state.isConnected);
  console.log('user pubkey', store.state.userPublicKey);  
  console.log('publicKey', publicKey.value);
  

  const walletPublicKey = publicKey.value;
  const isConnected = store.state.isConnected;
  if (walletPublicKey === null && isConnected === false) {
    await login();
    return;
  }
  if (walletPublicKey === null) {
    await login();
    return;
  }
  console.log('walletPublicKey', walletPublicKey.toString());
  if (store.state.isConnected === 'true' && store.state.userPublicKey !== '') {
     
    if (walletPublicKey === null || walletPublicKey.toString() !== store.state.userPublicKey) {
      store.commit('resetToDefaultSettings');
      await login(); // re-login if the public key changes
    } else {
      // The user is already connected and the public key matches
      userData.value = store.state.userData;
      if (!userData.value) {
        await store.dispatch('fetchAndStoreUserData', store.state.userPublicKey);
        userData.value = store.state.userData;
      }
    }
  } 
  if (walletPublicKey !== null && store.state.isConnected === false) {
    console.log('test');
    await login();
  }
};

onMounted(() => {
  
  socket.on('updateUser', (data) => {
    userData.value = data;
  });
});

onBeforeUnmount(() => {
  socket.off('tradeEvent');
  socket.off('createEvent');
  socket.off('updateUser');
});

watch(isLoading, (newVal) => {
  if (!newVal && userData.value) {
    isLoginHappening.value = true;
  }
});

watch(
  () => [connected.value, publicKey.value, store.state.isConnected],
  async () => {
    checkLoginStatus();
  },
  { immediate: true }
);
</script>



<template>
    <!-- this header -->
    <header v-if="showHeader" class="bg-primary border-b-2">
      <marqueeHeader />
      <div class="wrap-header flex gap-5 py-5 justify-between text-gray-600 items-center">
        <div class="flex gap-2 font-bold text-gray-800 flex-no-shrink items-center font-super">
          <div class="flex gap-8 items-center">
            <div class="grid">
              <router-link to="/all" exact class="ml-1 p-1.5">
                <span class="sr-only">wen moon</span>
                <img class="h-[28px] w-auto" src="@/assets/logo/mooney.svg" alt="">
              </router-link>
            </div>
            <router-link to="/all" exact class="hidden lg:block cursor-pointer border-2 border-transparent hover:border-2 hover:border-gray-900 px-1.5 pt-1 pb-1.5 rounded-xl" active-class="active-link">Home</router-link>
          </div>
          <router-link to="/create" exact class="hidden lg:block cursor-pointer border-2 border-transparent hover:border-2 hover:border-gray-900 px-1.5 pt-1 pb-1.5 rounded-xl" active-class="active-link">Create</router-link>
         
        </div>
        
        <div class="mr-3 flex gap-2.5 items-center">
          <router-link v-if="store.state.isConnected || store.state.userPublicKey !== ''" :to="'/profile/' + store.state.username" exact class="item cursor-pointer hover:font-bold relative group">
            <span class="relative z-10 flex text-nowrap items-center px-2 py-1 h-10 overflow-hidden font-medium text-gray-800 transition-all duration-300 ease-out border-2 border-gray-900 rounded-lg bg-primary transform -translate-y-1 -translate-x-1 group-hover:translate-y-0 group-hover:translate-x-0">
                <span class="relative font-super font-bold">
                  <Icon icon="basil:settings-solid" class="text-xl"/>
                </span>
              </span>
            <span class="absolute bottom-[1px] right-[1px] w-full h-10 bg-gray-900 rounded-lg transition-all duration-300 ease-out group-hover:bg-white"></span>
          </router-link>
          <div class="text-sm">
              <div class="flex gap-2 justify-end">
                <a v-if="!store.state.isConnected || publicKey === null" @click="openWalletModal" class="cursor-pointer relative inline-block text-sm group">
                  <span class="relative z-10 flex text-nowrap items-center px-6 py-1 h-10 overflow-hidden font-medium text-gray-800 transition-all duration-300 ease-out border-2 border-gray-900 rounded-lg bg-primary transform -translate-y-1 -translate-x-1 group-hover:translate-y-0 group-hover:translate-x-0">
                    <span class="relative font-super font-bold">Connect</span>
                  </span>
                  <span class="absolute bottom-[1px] right-[1px] w-full h-10 bg-gray-900 rounded-lg transition-all duration-300 ease-out group-hover:bg-white"></span>
                </a>
                <div v-else @click="openUserModal" class="text-gray-800 text-sm grid relative group" type="button" aria-haspopup="dialog" aria-expanded="false" aria-controls="radix-:r3:" data-state="closed">
                  <div class="z-10 flex items-center gap-1 px-2 cursor-pointer py-1 h-10 transition-all duration-300 ease-out border-2 border-gray-900 rounded-lg bg-primary transform -translate-y-1 -translate-x-1 group-hover:translate-y-0 group-hover:translate-x-0">
                    <span class="hidden sm:block"> </span>
                    <div>
                      <img crossorigin="anonymous" alt="" loading="lazy" width="16" height="16" decoding="async" data-nimg="1" class="w-4 h-4 rounded-full object-contain" 
                      :src="userData?.profile_image || 'https://wen.wenmoon.gg/ipfs/QmVvEaHAkVZML4otZgtWFvpapsKFPwtAhZrxwir9jVib8D?img-width=64&amp;img-dpr=2&amp;img-onerror=redirect'" style="color: transparent; display: block;">
                    </div>
                    <div class="max-w-[75px] truncate sono">{{ userData?.username === publicKey.toString() ? userData?.username.substring(0,6) : userData?.username }}</div>
                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4 6H11L7.5 10.5L4 6Z" fill="currentColor"></path>
                    </svg>
                  </div>
                  <span class="absolute bottom-[1px] right-[1px] w-full h-10 bg-gray-900 rounded-lg transition-all duration-300 ease-out group-hover:bg-white"></span>
                </div>
              </div>
              
             <div v-if="isClient" class="hidden">
              <wallet-multi-button />
             </div>
          </div>
        </div>
      </div>
      <userModal v-if="isUserModalOpen" @close-modal="isUserModalOpen = false" :userData="userData" :publicKey="publicKey?.toString()"/>
    </header>
    
    <howTo v-if="isHowToModalOpen" @close-modal="isHowToModalOpen = false" />
    
</template>
<style scoped>
.active-link {
  @apply bg-gray-900 text-white;
}
</style>

  