<template>
    <div class="px-3 lg:pb-3 text-gray-400 text-sm footer">
        <div class="flex text-xs pb-3 lg:pb-0 flex-col gap-2 lg:gap-0 lg:flex-row justify-between items-center">
            <p class="flex-shrink-0">© wenmoon.gg 2024</p>
            <p class="text-center text-gray-400">
                This site is protected by reCAPTCHA and the Google 
                <a href="https://www.google.com/intl/en/policies/privacy/" target="_blank" rel="noopener noreferrer" class="underline">
                    Privacy Policy
                </a> and 
                <a href="https://www.google.com/intl/en/policies/terms/" target="_blank" rel="noopener noreferrer" class="underline">
                    Terms of Service
                </a> apply.
            </p>  
        </div>
    </div>
</template>
<style scoped>
.footer {
   position:absolute;
   bottom:0;
   width:100%;

}
</style>