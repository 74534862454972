import { createSSRApp, createApp } from 'vue';
import App from './App.vue';
import { createHead } from '@vueuse/head';
import { VueReCaptcha } from "vue-recaptcha-v3";
import router from './router';
import store from './vuex';

import "./assets/tailwind.css";
import "./assets/animate.css";
import "./assets/sass/css/moon.css";
import 'solana-wallets-vue/styles.css'
import './main.css';


export default function buildApp() {
  const isSSR = typeof window === 'undefined';
  const app = (isSSR ? createSSRApp(App) : createApp(App));
  const head = createHead();
  app.config.warnHandler = () => {};
  app.use(store);
  app.use(router);
  app.use(head);
  app.use(VueReCaptcha, { 
    siteKey: "6Lf1nx0qAAAAAJpK-fFUk2rmCXIpuqnwbIhN_7XW",
    loaderOptions: {}
  });
  
  return { app, router, store };
}