import io from "socket.io-client";
import { debounce } from 'lodash';

export const socket = io("http://localhost:3000");

socket.on("connect", () => {
    console.log("Connected to server");
});

socket.on("disconnect", () => {
    console.log("Disconnected from server");
});

export const createDebouncedFunction = (func, delay) => {
    return debounce(async (...args) => {
        
        await func(...args);
    }, delay);
};

export function truncateToThreeDecimals(number) {
    const truncated = Math.floor(number * 1000) / 1000;
    return truncated.toString();
}

export function addCommasToNumber(number) {
    // Convert the input to a number if it's a string
    const num = typeof number === 'string' ? parseFloat(number) : number;
  
    if (isNaN(num)) {
      throw new Error('Input must be a valid number or numeric string');
    }
  
    return num.toLocaleString();
}

export const deserializeNumber = (num) => {
    if (num === null || num === undefined) {
        return 0;
    }
    const math = num / 10 ** 6;
    return math;
};

export const deserializeSolNumber = (num) => {
    if (num === null || num === undefined) {
        return 0;
    }
    const math = num / 10 ** 9;
    return math;
};

export const formatNumber = (num) => {
    if (num == null || isNaN(num)) {
        return 'Invalid number'; // Handle cases where `num` is null or not a number
    }
    if (Math.abs(num) >= 1.0e+12) {
        return (num / 1.0e+12).toFixed(2) + 't'; // Trillions
    }
    if (Math.abs(num) >= 1.0e+9) {
        return (num / 1.0e+9).toFixed(2) + 'b'; // Billions
    }
    if (Math.abs(num) >= 1.0e+6) {
        return (num / 1.0e+6).toFixed(2) + 'm'; // Millions
    }
    if (Math.abs(num) >= 1.0e+3) {
        return (num / 1.0e+3).toFixed(2) + 'k'; // Thousands
    }
    return num.toFixed(2); // Default: no abbreviation needed
};

export function addCommas(num) {
    if (isNaN(num)) return '';
  
    // Convert to a float and fix to 2 decimal places
    const fixedNum = parseFloat(num).toFixed(2);
  
    // Split the number into integer and decimal parts
    const [integerPart, decimalPart] = fixedNum.split('.');
  
    // Add commas to the integer part
    const integerWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  
    // Combine integer part with decimal part
    return `${integerWithCommas}.${decimalPart}`;
}

export const applySortAndFilter = (data, sortBy, orderBy) => {
    let filteredData = data;
  
    // Apply sorting
    if (sortBy) {
      filteredData = filteredData.sort((a, b) => {
        const aValue = a[sortBy.value];
        const bValue = b[sortBy.value];
        
        if (orderBy === 'asc') {
          return aValue > bValue ? 1 : -1;
        } else {
          return aValue < bValue ? 1 : -1;
        }
      });
    }
  
    return filteredData;
  };

export function getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }